@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Iceland&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400&display=swap');
h1{
	font-family: 'Iceland', cursive;

}
p,th{
	font-family: 'Orbitron', sans-serif;
}
.link,td{
	font-family: 'Cairo', sans-serif;

}
h6.subtitle,li{
	font-family: 'Cairo', sans-serif;
	font-size:26px;
}

.carousel-container {
 padding-top: 90px;
position: relative;

}

.carousel{
	box-shadow:4px 6px 6px 6px rgba(0, 0, 0, 0.75);
	width: 100%;
	background-color: rgba(60, 60, 60, 0.949);
}
.carousel-container::before{
	background-image: url('./components/images/backgrounimg.jfif');
	opacity: 0.5;
	content:"";
position:absolute;
top:0;
bottom:0;
right:0;
left:0;
height: auto;
width: auto;
z-index:-1;
background-repeat: no-repeat;
background-size: 100% 100%;
}




h4.title{
	font-family: 'Iceland', cursive;
	color:hsl(141, 95%, 49%);
}
.submitbtn{
	background-color:hsl(141, 95%, 49%) ;
	color: #000;
	font-family: 'Orbitron', sans-serif;
}



#my-nav{
	font-size: 26px;
	font-weight: bold;
	background-color: rgba(0, 0, 0, 0.38);
	font-family: 'Iceland', cursive;
	justify-content: center;
  }
#my-nav  a{

  
	margin: 10px;
	color:hsl(141, 95%, 49%);
	justify-content: center;
	align-items: center;
	display:flex;
	height: 55px;
	
}


#my-nav a:hover{
  background-color:hsl(141, 95%, 49%);
  color:  #fff;
  border-radius: 7px;
 
}

#my-nav .active{
	background-color:hsl(141, 95%, 49%);
	color:  #000;
	border-radius: 7px;
}



hr.solid {
	border-top: 3px solid ;
  }

  .imgEffect  {

	opacity: 1;
	
  }
  
  .imgEffect:hover {opacity: 0.5;}
  .underline span {
	display: inline-block;	
  }
  
  .underline span::after
  {

	content: '';
	height: 10px;
	width:70%;
	background: hsl(141, 95%, 49%);
	border-radius: 5px;
	display: block;
	margin: 7px 15%;
	

  }
  .indicator {
	cursor: pointer;
	width: 50px;
	height: 10px;
	background:rgba(0, 0, 0, 0.693);
	border-radius: 5px;
	margin-left: 20px;

  }
  
  .indicator.active {
	color: #fff;
	background: hsl(141, 95%, 49%);
  }


  .scroll-downs {
	
	align-self: flex-end;
	cursor: pointer;
	
	width :34px;
	height: 85px;
  }
  .mousey {
	margin-left: 5px;
	width: 3px;
	padding: 10px 15px;
	height: 35px;
	border: 3px solid hsl(141, 95%, 49%);
	border-radius: 25px;
	opacity: 1;
	box-sizing: content-box;
  }
  .scroller {
	width: 3px;
	height: 10px;
	border-radius: 25%;
	background-color:hsl(141, 95%, 49%) ;
	animation-name: scroll;
	animation-duration: 2.2s;
	animation-timing-function: cubic-bezier(.15,.41,.69,.94);
	animation-iteration-count: infinite;
  }
  @keyframes scroll {
	0% { opacity: 0; }
	10% { transform: translateY(0); opacity: 1; }
	100% { transform: translateY(15px); opacity: 0;}
  }

  .loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
   
}
.loader-videos{
	text-align: center;
	justify-content: center;
	display: flex;
	position: absolute;
	top: 50%;
	

}

::-webkit-scrollbar {
	width : 10px ;
	
 }
 ::-webkit-scrollbar-track {
	background-color : rgba(0, 0, 0, 0.8) ;
 
 }
 
 ::-webkit-scrollbar-thumb {
	background-color : hsl(141, 95%, 49%);
	border-radius: 10px;
 }
 
 ::-webkit-scrollbar-thumb:hover {
 
	background-color:rgb(220, 228, 222)
   }

